<template>
  <v-container fluid fill-height class="container d-flex">
    <form @submit.prevent="login">
      <div class="d-flex justify-center">
        <img src="../assets/iGoalLogo.png" height="100" width="80" />
      </div>
      <label for="username">Phone</label>
      <input type="text" placeholder="Phone" id="username" v-model="phone" />

      <label for="password">Password</label>
      <input
        type="password"
        placeholder="Password"
        id="password"
        v-model="password"
      />
      <button type="submit">Log In</button>
    </form>
    <span class="align-self-end justify-center"
      >© {{ new Date().getFullYear() }} — Developed by Premdas VM</span
    >
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      phone: "",
      password: "",
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post("user/login", {
          phone: this.phone,
          password: this.password,
        });
        localStorage.setItem("accessToken", response.data.accessToken);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("accessToken")}`;
        this.$router.push("/");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.container {
  align-items: center;
  justify-content: center;
  /* background: url("../assets/login.webp"); */
  background: linear-gradient(0deg, rgba(8, 8, 8, 0.8), rgba(8, 8, 8, 0.8)),
    url("../assets/login.webp") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
form {
  height: 520px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}

form * {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}
form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}
label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
}
input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}
::placeholder {
  color: #e5e5e5;
}
button {
  margin-top: 50px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
</style>
